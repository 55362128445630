import React, {useState, useEffect} from 'react'
// import {useNavigate} from 'react-router-dom'
// import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';


  

function Foot() {





return (


    <div className="foot">
    
    <center>
    <img src="https://images.tapology.com/gyms/logos/1168/profile/1168-chicago-fight-team.jpg?1656434969" alt="https://images.tapology.com/gyms/logos/1168/profile/1168-chicago-fight-team.jpg?1656434969" />
    </center>
    </div>
    
    
    );
}


export default Foot;